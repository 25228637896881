<template>
  <div class="root">
    <div>
      seat
    </div>

    <lotterySeat
      :fightRoom="fightRoom"
      :fightResult="fightResult"
      :seat="seat"
      :singlePlayTime="singlePlayTime"
    >seatT</lotterySeat>

  </div>
</template>

<script>
import lotterySeat from "@/components/pkHome/lotterySeat.vue";

export default {
  data() {
    return {
      fightResult: [
        {
          boxId: 2,
          boxName: "测试1-2",
          boxPrice: 50,
          fightId: 918,
          fightRoundNumber: 1,
          id: 6049,
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
          isShow: 0,
          ornamenName: "M4A1 消音型 | 氮化处理",
          ornamentsId: 3715,
          ornamentsLevelId: 1,
          ornamentsPrice: 4.22,
          source: "0",
          status: "0",
          userId: 31,
          boxInfo: {
            boxId: 2,
            boxImg01: "",
            boxImg02: "",
            number: 1,
            ornaments: [
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 18,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IE5lby1Ob2lyIChGaWVsZC1UZXN0ZWQp.png",
                itemName: "M4A4 | 黑色魅影 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3604,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 31.89,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 12,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgRW1waG9yb3NhdXItUyAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "M4A1 消音型 | 隐伏帝王龙 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3645,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 3.82,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 17,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgRmxhc2hiYWNrIChGaWVsZC1UZXN0ZWQp.png",
                itemName: "M4A1 消音型 | 闪回 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3648,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 2.68,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 11,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTmlnaHQgVGVycm9yIChGaWVsZC1UZXN0ZWQp.png",
                itemName: "M4A1 消音型 | 夜无眠 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3598,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 2.61,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 15,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IFVyYmFuIEREUEFUIChNaW5pbWFsIFdlYXIp.png",
                itemName: "M4A4 | 都市 DDPAT (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3856,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 1.47,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 19,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTXVkLVNwZWMgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 泥斑迷彩 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 4109,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 1.27,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 16,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgQm9yZWFsIEZvcmVzdCAoTWluaW1hbCBXZWFyKQ==.png",
                itemName: "M4A1 消音型 | 北方森林 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3889,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 0.94,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 20,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IFVyYmFuIEREUEFUIChGaWVsZC1UZXN0ZWQp.png",
                itemName: "M4A4 | 都市 DDPAT (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3693,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 0.8,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "11.11%",
                boxId: 2,
                id: 13,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBMS1TIHwgTml0cm8gKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A1 消音型 | 氮化处理 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "11.11%",
                ornamentsId: 3715,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "11.11%",
                usePrice: 4.22,
              },
            ],
          },
        },
        {
          boxId: 3,
          boxName: "测试1-3",
          boxPrice: 90,
          fightId: 918,
          fightRoundNumber: 2,
          id: 6051,
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
          isShow: 0,
          ornamenName: "AK-47 | 二西莫夫",
          ornamentsId: 3609,
          ornamentsLevelId: 1,
          ornamentsPrice: 148.85,
          source: "0",
          status: "0",
          userId: 31,
          boxInfo: {
            boxId: 3,
            boxImg01: "",
            boxImg02: "",
            number: 1,
            ornaments: [
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 28,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/TTRBNCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "M4A4 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3858,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 888.49,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 22,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3606,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 784.18,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 30,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoV2VsbC1Xb3JuKQ==.png",
                itemName: "AWP | 二西莫夫 (破损不堪)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 4177,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 557.22,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 26,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQXNpaW1vdiAoQmF0dGxlLVNjYXJyZWQp.png",
                itemName: "AWP | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3854,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 429.61,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 23,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChNaW5pbWFsIFdlYXIp.png",
                itemName: "AK-47 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3713,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 271.31,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 29,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoTWluaW1hbCBXZWFyKQ==.png",
                itemName: "P90 | 二西莫夫 (略有磨损)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1985,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 98.74,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 25,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDkwIHwgQXNpaW1vdiAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "P90 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 1912,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 47.23,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 24,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "P250 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9059,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 17.69,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 3,
                id: 27,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UDI1MCB8IEFzaWltb3YgKEJhdHRsZS1TY2FycmVkKQ==.png",
                itemName: "P250 | 二西莫夫 (战痕累累)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 9270,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 9.58,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "100.00%",
                boxId: 3,
                id: 21,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QUstNDcgfCBBc2lpbW92IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AK-47 | 二西莫夫 (久经沙场)",
                level: "金色",
                odds: 1,
                oddsPercentum: "10.00%",
                ornamentsId: 3609,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "100.00%",
                usePrice: 148.85,
              },
            ],
          },
        },
        {
          boxId: 4,
          boxName: "测试1-4",
          boxPrice: 100,
          fightId: 918,
          fightRoundNumber: 3,
          id: 6053,
          imageUrl:
            "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
          isShow: 0,
          ornamenName: "AWP | 尼罗河沃土",
          ornamentsId: 3662,
          ornamentsLevelId: 1,
          ornamentsPrice: 8.64,
          source: "0",
          status: "0",
          userId: 31,
          boxInfo: {
            boxId: 4,
            boxImg01:
              "http://192.168.1.39:8082/profile/upload/2024/02/26/download_20240226180829A001.png",
            boxImg02: "http://192.168.1.39:8082",
            number: 1,
            ornaments: [
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 39,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgRHVhbGl0eSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 金粉肆蛇 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3603,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 20.63,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 34,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUEFXIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 猫猫狗狗 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3626,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 13.8,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 36,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQWNoZXJvbiAoTWluaW1hbCBXZWFyKQ==.png",
                itemName: "AWP | 冥界之河 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3806,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 4.89,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 4,
                id: 46,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/Q2x1dGNoIENhc2U=.png",
                itemName: "命悬一线武器箱",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 6895,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 2.68,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 37,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGl0IFZpcGVyIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 响尾蛇 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3915,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 2.28,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 35,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQ2FwaWxsYXJ5IChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 毛细血管 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3873,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 2.08,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 4,
                id: 44,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/RnJhY3R1cmUgQ2FzZQ==.png",
                itemName: "裂空武器箱",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 6917,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 2.08,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 33,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGl0IFZpcGVyIChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AWP | 响尾蛇 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3870,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 1.54,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 4,
                id: 42,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UGFyaXMgMjAyMyBDb250ZW5kZXJzIFN0aWNrZXIgQ2Fwc3VsZQ==.png",
                itemName: "2023年巴黎锦标赛竞争组印花胶囊",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 6907,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 1.34,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 4,
                id: 45,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UGFyaXMgMjAyMyBMZWdlbmRzIFN0aWNrZXIgQ2Fwc3VsZQ==.png",
                itemName: "2023年巴黎锦标赛传奇组印花胶囊",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 6920,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 1.27,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 1,
                anchorOddsPercentum: "0.10%",
                boxId: 4,
                id: 41,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/UmVjb2lsIENhc2U=.png",
                itemName: "反冲武器箱",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 6897,
                ornamentsLevelId: 1,
                realOdds: 1,
                realOddsPercentum: "0.10%",
                usePrice: 1.21,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 4,
                id: 43,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/U25ha2ViaXRlIENhc2U=.png",
                itemName: "蛇噬武器箱",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 6901,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 1.14,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 31,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQ2FwaWxsYXJ5IChGaWVsZC1UZXN0ZWQp.png",
                itemName: "AWP | 毛细血管 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3826,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 0.8,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 32,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgU2FmYXJpIE1lc2ggKEZpZWxkLVRlc3RlZCk=.png",
                itemName: "AWP | 狩猎网格 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3821,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 0.33,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 0,
                anchorOddsPercentum: "0.00%",
                boxId: 4,
                id: 47,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/U3RpY2tlciB8IEJMQVNULnR2IHwgUGFyaXMgMjAyMw==.png",
                itemName: "印花 | BLAST.tv | 2023年巴黎锦标赛",
                level: "金色",
                odds: 1,
                oddsPercentum: "0.10%",
                ornamentsId: 11480,
                ornamentsLevelId: 1,
                realOdds: 0,
                realOddsPercentum: "0.00%",
                usePrice: 0.07,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 40,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgQmxhY2sgTmlsZSAoRmllbGQtVGVzdGVkKQ==.png",
                itemName: "AWP | 尼罗河沃土 (久经沙场)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3662,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 8.64,
              },
              {
                anchorOdds: 100,
                anchorOddsPercentum: "9.99%",
                boxId: 4,
                id: 38,
                imageUrl:
                  "https://img.zbt.com/e/steam/item/730/QVdQIHwgUGhvYm9zIChNaW5pbWFsIFdlYXIp.png",
                itemName: "AWP | 火卫一 (略有磨损)",
                level: "金色",
                odds: 100,
                oddsPercentum: "9.93%",
                ornamentsId: 3937,
                ornamentsLevelId: 1,
                realOdds: 100,
                realOddsPercentum: "9.99%",
                usePrice: 6.5,
              },
            ],
          },
        },
      ],
      fightRoom: {
        beginTime: "2024-03-23 15:39:29",
        boxData: {
          2: { boxId: 2, number: 1, boxImg01: "", boxImg02: "" },
          3: { boxId: 3, number: 1, boxImg01: "", boxImg02: "" },
        },
        boxPriceTotal: 140,
        createTime: "2024-03-23 15:39:20",
        id: 913,
        model: "0",
        playerNum: 2,
        roundNumber: 2,
        seats: [
          {
            code: 0,
            avatar:
              "http://192.168.1.39:8082/profile/upload/2024/03/16/0073Cjx6ly1gntvljnj2vg303c03cdg1_20240316114704A001.jpg",
            status: 2,
            nickName: "康建承",
            playerId: 31,
            awardTotalPrices: 0,
          },
          {
            code: 1,
            avatar:
              "http://192.168.1.39:8082/profile/upload/2024/03/15/2021682133020069_20240315145929A002.jpg",
            status: 2,
            nickName: "危姬巧",
            playerId: 32,
            awardTotalPrices: 0,
          },
        ],
        status: 1,
        userId: 31,
      },
      seat: {
        code: 0,
        avatar:
          "http://192.168.1.39:8082/profile/upload/2024/03/16/0073Cjx6ly1gntvljnj2vg303c03cdg1_20240316114704A001.jpg",
        status: 2,
        nickName: "康建承",
        playerId: 31,
        awardTotalPrices: 0,
      },
      singlePlayTime: 2,
      speed: 0,
    };
  },
  components: {
    lotterySeat,
  },
  methods: {
    resLocationChange(L) {
      //console.log("test01位置改变 "+JSON.stringify(L))
      this.resLocation = L;
    },
    onRun(newv) {
      console.log("speed=" + newv);
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
}

.lotteryRounds {
  position: relative;
  height: 50%;
  border: 1px solid white;
}

.boxItem {
  transition: all 2s linear;
}

.boxItem:hover {
  transform: translate(0px, 200px);
}

.list_animate {
  // transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0.1s;
  // transform: translateY(-1947px);
}

.list_animate:hover {
  // transition: all 5.5s cubic-bezier(0, 0, 0, 1) 0.1s;
  // transform: translateY(-1947px);
}

.list_box {
  width: 92px;
  height: 98px;
  background-size: 100% 100%;
}
</style>
